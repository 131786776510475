import React from "react";
import styles from "./Payments.module.css";
import SideBar from "../SideBar/SideBar";
import Download from "../../Images/download.png";
import Serch from "../../Images/serch.png";
import Calender from "../../Images/calender.png";
import PaymentsTable from "./PaymentsTable";
const Payments = () => {
  return (
    <div className={styles.shipmentMain}>
      <SideBar />
      <div className={styles.shipmentContainer}>
        <div className={styles.controlMainButtonText}>
          <button className={styles.controlBtn}>غير موثق</button>
          <div className={styles.mainText}>ATHERR</div>
        </div>
        <div className={styles.shipmentButtondivMain}>
          <button className={styles.shipmentBtn}>انشاء +</button>
          <div className={styles.btnDivText}>عمليات الدفع</div>
        </div>
        <div className={styles.tableButtonMain}>
          <div className={styles.tableBtnMain}>
            <div className={styles.leftBtnMain}>
              <img src={Download} alt="" className={styles.downIcon} />
              <div className={styles.serchIconMain}>
                <img src={Serch} alt="" className={styles.serchIcon} />
              </div>
            </div>
            <div className={styles.rightBtnMain}>
              <button className={styles.headbtn1}>
                <img src={Calender} alt="" className={styles.calIcon} />
                الى تاريخ
              </button>
              <button className={styles.headbtn1}>
                <img src={Calender} alt="" className={styles.calIcon} />
                من تاريخ
              </button>

              <select id="" name="" className={styles.headbtn2}>
                <option value="اصدار بواسطة">الحالة</option>
              </select>
              <button className={styles.headbtn12}>تاريخ الاستلام</button>

              <button className={styles.headbtn7}>المبلغ</button>
              <select id="" name="" className={styles.headbtn8}>
                <option value="نوع الدفع">نوع الدفع</option>
              </select>
              <select id="" name="" className={styles.headbtn9}>
                <option value="شركة الشحن">شركة الشحن</option>
              </select>
              <button className={styles.headbtn11}>سم المتجر الاسم</button>
            </div>
          </div>
          <PaymentsTable />
        </div>
      </div>
    </div>
  );
};

export default Payments;
