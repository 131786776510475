import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import styles from "./Home.module.css";
import "./Accordian.css"
const Accordian = () => {
  return (
        <Accordion>
      <Accordion.Item eventKey="0" className={styles.accordiancont}>
        <Accordion.Header className={styles.accordianinnerHeading}>
        كيف يمكنني اصدار البوليصة؟
        </Accordion.Header>
        <Accordion.Body className={styles.accordianText}>
        بمجرد تسجيل الدخول واختيار الشركة المناسبة لك ثم تعبئة معلومات المرسل والمستلم والدفع بالطريقة التي تناسبك 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className={styles.accordiancont}>
        <Accordion.Header className={styles.accordianinnerHeading}>
        كيف اتتبع طلبات العملاء
            
            </Accordion.Header>
        <Accordion.Body className={styles.accordianText}>
        عبر قائمة شحناتي بعد تسجيل دخولك يمكنك متابعة جميع شحناتك 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className={styles.accordiancont}>
        <Accordion.Header className={styles.accordianinnerHeading}>
        هل يمكنني الغاء البوليصة واسترداد اموالي 
        </Accordion.Header>
        <Accordion.Body className={styles.accordianText}>
        نعم اذا كنت لاي سبب لا ترغب باستعمال بوليصة الشحن، سيتم ارجاع المبلغ لك خلال ١٤ يوم عمل

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className={styles.accordiancont}>
        <Accordion.Header className={styles.accordianinnerHeading}>
        متى تصل شحنتي الى وجهتها النهائية؟ 
        </Accordion.Header>
        <Accordion.Body className={styles.accordianText}>
        خلال ٣ الى ٥ ايام عمل ويختلف من شركة الى اخرى ومنطقة  التسليم والاستلام

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className={styles.accordiancont}>
        <Accordion.Header className={styles.accordianinnerHeading}>
        هل يوجد لديكم شحن الى دول الخليج 
        </Accordion.Header>
        <Accordion.Body className={styles.accordianText}>
        نعم تفضل بالتواصل معنا على رقم الواتس اب الخاص بالدعم الفني وسنقوم باصدارها خلال اقل من ٢٤ ساعة 

        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>
  )
}

export default Accordian
