import React from 'react'
import styles from "./SideBar.module.css"
import "./SideBar.css"
import Logo from "../../Images/sidebarlogo.png"
import Icon1 from "../../Images/sideicon1.png"
import Icon2 from "../../Images/sideicon2.png"
import Icon3 from "../../Images/sideicon3.png"
import Icon4 from "../../Images/sideicon4.png"
import Icon5 from "../../Images/sideicon5.png"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FiMenu } from "react-icons/fi";
import { Link, useLocation } from 'react-router-dom';
const SideBar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index);
    };
  

    const location = useLocation();
  return (
    <>
    <Button variant="primary" className="canvusBtn d-xl-none" onClick={handleShow}>
    <FiMenu />
    </Button>

    <Offcanvas show={show} onHide={handleClose}  responsive="xl" placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <div className={styles.sidebarMain}>
      <div className={styles.sidebarContainer}>
        <div className={styles.sidelogo}>
            <img src={Logo} alt="" className={styles.logoImg} />
        </div>
        <div className={styles.sidebarlogomain  + (activeIndex === 0 ? ' ' + styles.active : '')} onClick={() => handleClick(0)}>
        <Link to='/controlboard' className={styles.sidelogoMain}>
                <div className={location.pathname === '/controlboard' ? styles.active : styles.sideText}>
                    لوحة التحكم
                </div>
                <div className={styles.sideIcon}> 
                    <img src={Icon1} alt="" className={styles.sideIcn} />
                </div>
            </Link>
            </div>

        <Link to='/shipment' className={styles.sidelogoMain}>
            <div className={location.pathname === '/shipment' ? styles.active : styles.sideText}>
            جميع الشحنات
            </div>
            <div className={styles.sideIcon}>
            <img src={Icon2} alt="" className={styles.sideIcn} />
            </div>
        </Link>
        <Link to='/payment' className={styles.sidelogoMain}>
            <div className={location.pathname === '/payment' ? styles.active : styles.sideText}>
            عمليات الدفع
            </div>
            <div className={styles.sideIcon}>
            <img src={Icon3} alt="" className={styles.sideIcn} />
            </div>
        </Link>
        <Link to='/shipcompany' className={styles.sidelogoMain}>
            <div className={location.pathname === '/shipcompany' ? styles.active : styles.sideText}>
            شركات الشحن
            </div>
            <div className={styles.sideIcon}>
            <img src={Icon4} alt="" className={styles.sideIcn} />
            </div>
        </Link>
        <Link to='/profile' className={styles.sidelogoMain}>
            <div className={location.pathname === '/profile' ? styles.active : styles.sideText}>
            ملفي
            </div>
            <div className={styles.sideIcon}>
            <img src={Icon5} alt="" className={styles.sideIcn} />
            </div>
        </Link>
      </div>
    </div>
      </Offcanvas.Body>
    </Offcanvas>
  </>
    
  )
}

export default SideBar
