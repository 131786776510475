import React, { useState } from "react";
import styles from "./SignUp.module.css";
import SignBg from "../../Images/signbg.png";
import SignLogo from "../../Images/signlogo.png";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
const SignUp = () => {

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://q-ships.com/backend/api/registerUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        swal("SignUp!", "SignUp Register Successfully!", "success");
        console.log('User registered successfully:', data.user);
        navigate("/");
        // Optionally, you can redirect the user to another page upon successful registration
        // window.location.href = '/success'; // Redirect to a success page
      } else {
        console.error('Registration failed:', data.message);
        // Optionally, you can display an error message to the user
        // alert(data.message);
      }
    } catch (error) {
      console.error('Registration failed:', error.message);
      // Optionally, you can display an error message to the user
      // alert('Registration failed. Please try again later.');
    }

    // Clear form data after submission
    setFormData({
      email: '',
      password: '',
      confirmpassword: '',
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={styles.signupMain}>
      <div className={styles.signupContainer}>
        <div className={styles.signupLeftsideMain}>
          <img src={SignBg} alt="" className={styles.signBgImg} />
          <img src={SignLogo} alt="" className={styles.signlogoImg} />
        </div>
        <div className={styles.signupRightsideMain}>
          <div className={styles.signHeading}>Signup</div>
          <div className={styles.signText}>
            Signup with your data that you entered during registration.
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.signInputMain}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="البريد  الالكتروني"
                className={styles.signinput}
              />
            </div>
            <div className={styles.signInputMain}>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                placeholder="كلمة المرور *"
                className={styles.signinput}
              />
            </div>
            <div className={styles.signInputMain}>
              <input
                type="password"
                name="confirmpassword"
                value={formData.confirmpassword}
                onChange={handleChange}
                required
                placeholder="تأكيد كلمة المرور "
                className={styles.signinput}
              />
            </div>
            <div className={styles.signButton}>
              <button className={styles.signBtn} type="submit">
                Signup
              </button>
            </div>
          </form>

          <div className={styles.signLastText}>
            بتسجيل الدخول انت توافق على سياسة الخصوصية وشروط
          </div>
          <div className={styles.signLastText}>الاستخدام.</div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
