import React, { useState } from "react";
import styles from "./ControlBoard.module.css";
import Icon1 from "../../Images/btnicon.png";
import Card1 from "../../Images/cnImg1.png";
import Card2 from "../../Images/cnImg2.png";
import Card3 from "../../Images/cnImg3.png";
import Star from "../../Images/star.png";
import SideBar from "../SideBar/SideBar";
const ControlBoard = () => {
  const [state, setstate] = useState(false);

  const showDiv = () => {
    setstate(!state)
  }


  return (
    <div className={styles.controlMain}>
      <SideBar/>
      <div className={styles.controlContainer}>
        <div className={styles.controlMainButtonText}>
          <button className={styles.controlBtn}>غير موثق</button>
          <div className={styles.mainText}>ATHERR</div>
        </div>
        <div className={styles.control2mainbuttonText}>
          <div className={styles.control2ContainerbuttonText}>
            <div className={styles.controlButton2main}>
              <button className={styles.controlBtn2}>
                تتبع
                <img src={Icon1} alt="" className={styles.btnIcon} />
              </button>
            </div>
            <div className={styles.control2Text}>تتبع شحنتك هنا</div>
          </div>
        </div>

        <div className={styles.cardMain}>
            <div className={styles.card1Main}>
                <img src={Card1} alt="" className={styles.cardImg1} />
                <div className={styles.cardTextMain}>
                    <div className={styles.cardtext1}>
                    ريال
                    </div>
                    <div className={styles.cardtext2}>
                    23
                    </div>
                </div>
            </div>
            <div className={styles.card1Main}>
                <img src={Card2} alt="" className={styles.cardImg1} />
                <div className={styles.cardTextMain}>
                    <div className={styles.cardtext1}>
                    ريال
                    </div>
                    <div className={styles.cardtext2}>
                    21
                    </div>
                </div>
            </div>
            <div className={styles.showDivMain}>
            <div className={styles.card1Main} onClick={showDiv}>
                <img src={Card3} alt="" className={styles.cardImg1} />
                <div className={styles.cardTextMain}>
                    <div className={styles.cardtext1}>
                    ريال
                    </div>
                    <div className={styles.cardtext2}>
                    15
                    </div>
                </div>
          
              
            </div>
            {
                  state ?  
                  <div className={styles.TextIconDiv}>
                   <div className={styles.textIconMain}>
                  <div className={styles.TextIcon}>
                  توصيل سريع
                  </div>
                  <div className={styles.IconImage}>
                  <img src={Star} alt=""  className={styles.startIcon} />
                  </div>
              </div> 
                   <div className={styles.textIconMain}>
                  <div className={styles.TextIcon}>
                  تغليف الشحنة
                  </div>
                  <div className={styles.IconImage}>
                  <img src={Star} alt=""  className={styles.startIcon} />
                  </div>
              </div> 
                   <div className={styles.textIconMain}>
                  <div className={styles.TextIcon}>
                  الدفع عند التوصيل
                  </div>
                  <div className={styles.IconImage}>
                  <img src={Star} alt=""  className={styles.startIcon} />
                  </div>
              </div> 
              
              </div>: ""
                }
                </div>
        </div>
      </div>
    </div>
  );
};

export default ControlBoard;
