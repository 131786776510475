import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import styles from "./Footer.module.css";
import Icon1 from "../../Images/house.png"
import Icon2 from "../../Images/linkedin.png"
import Icon3 from "../../Images/instagram.png"
import Logo from "../../Images/footerlogo.png"
import { Link } from 'react-router-dom';

const Footer = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_1yynhpb',         // Your EmailJS service ID
      'template_0rxtkde',        // Your EmailJS template ID
      formData,   // Sending form data
      'xmjzaNItfvsTFO0DV'        // Your EmailJS public key
    ).then(
      (result) => {
        console.log('SUCCESS!', result.text);
        alert('Subscribed successfully!');
      },
      (error) => {
        console.error('FAILED...', error);
        alert('Failed to subscribe.');
      }
    );
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0); 
  };

  return (
    <div className={styles.footerMain}>
      <div className={styles.footerContainer}>
        <div className={styles.footerSection1Main}>
          <div className={styles.Section1Heading}>
            Get the freshest news from us
          </div>
          <div className={styles.Section1InputMainnn}>
            <form onSubmit={handleSubmit} className={styles.Section1InputMain}>
              <input
                type="email"
                name="email"  
                placeholder="Your email address…"
                className={styles.section1Input}
                value={formData.email}
                onChange={handleChange}
                required
              />
        
            <div className={styles.section1Button}>
                <button className={styles.section1Btn} type="submit">
                  Subscribe
                </button>
              </div>
            </form>

          </div>
        </div>
        <div className={styles.footerSection2Main}>
          <div className={styles.section2Heading}>العنوان</div>
          <div className={styles.section2Text}>
            شارع  وادي المياه، المنطقة  الشرقية،  المملكة  العربية  السعودية
          </div>
          <div className={styles.section2Heading2}>تواصل معنا:</div>
          <div className={styles.section2Text2}>+966508353505</div>
          <div className={styles.section2Text2}>Qshipscorp@gmail.com</div>
          <div className={styles.section2IconMain}>
            <div className={styles.sectionIcon1}>
              <img src={Icon1} alt="" className={styles.icon1} />
            </div>
            <div className={styles.sectionIcon1}>
              <img src={Icon2} alt="" className={styles.icon2} />
            </div>
            <div className={styles.sectionIcon1}>
              <img src={Icon3} alt="" className={styles.icon3} />
            </div>
          </div>
        </div>
        <div className={styles.footerSection3Main}>
          <div className={styles.section3Logo}>
            <img src={Logo} alt="" className={styles.footerlogo} />
          </div>
          <div className={styles.section3Text}>
            نحن  مؤسسة  مسجلة  في المملكة  العربية السعودية  متخصصة  في  نقل  الطرود  البريدية  والحلول  الإلكترونية  اللوجستية  نسعى  لخدمتكم  دائما  لنقدم لكم  حلولأ تقنية  لشحناتكم  باسعار  منافسة  وخدمات  احترافية
          </div>
        </div>
      </div>
      <div className={styles.footerline}></div>
      <div className={styles.footerLastMain}>
        <div className={styles.leftsideMain}>
          <div className={styles.leftText}>
            Copyrights 2024 <span>Qships</span> by <span>Waleed</span>
          </div>
        </div>
        <div className={styles.rightsideMain}>
          <Link to='/privacy' onClick={scrollToTop} style={{ textDecoration: "none" }}>
            <div className={styles.rightText1}>سياسة الخصوصية</div>
          </Link>
          <Link to='/terms' onClick={scrollToTop} style={{ textDecoration: "none" }}>
            <div className={styles.rightText1}>
            شروط الاستخدام
                </div>
          </Link>
        
        </div>
      </div>
    </div>
  );
};

export default Footer;
