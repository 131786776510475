import React, { useState, useEffect } from "react";
import styles from "./ResetPassword.module.css";
import SignBg from "../../Images/signbg.png";
import SignLogo from "../../Images/signlogo.png";
import axios from 'axios';
import swal from 'sweetalert';

const ResetPassword = () => {
    const [formData, setFormData] = useState({
        password: "",
    });
    const [token, setToken] = useState(""); // State to store token

    useEffect(() => {
        // Extract token from URL when component mounts
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log("Submitting password:", formData.password);
            console.log("Token:", token); // Make sure token is present
            const response = await axios.post('https://q-ships.com/backend/api/resetAdmin', { newPassword: formData.password,token });
            console.log(response.data);
            swal("Reset Password!", "Password reset successfully!", "success");
        } catch (error) {
            if (error.response) {
                console.error("Reset password failed:", error.response.data.message);
            } else {
                console.error("Reset password failed:", error.message);
            }
        }
        setFormData({
            password: "",
        });
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className={styles.signupMain}>
            <div className={styles.signupContainer}>
                <div className={styles.signupLeftsideMain}>
                    <img src={SignBg} alt="" className={styles.signBgImg} />
                    <img src={SignLogo} alt="" className={styles.signlogoImg} />
                </div>
                <div className={styles.signupRightsideMain}>
                    <div className={styles.signHeading}>
                        Reset Password
                    </div>
                    <div className={styles.signText}>
                        Enter your new password
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.signInputMain}>
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                placeholder='كلمة المرور الجديدة'
                                className={styles.signinput} />
                        </div>
                        <div className={styles.signButton}>
                            <button className={styles.signBtn} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
