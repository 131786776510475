import React from 'react'
import Header from './Components/Header/Header'
import Home from './Components/Home/Home'
import SignUp from './Components/SignUp/SignUp'
import Login from './Components/Login/Login'
import ForgotPassword from './Components/ForgotPassword/ForgotPassword'
import ControlBoard from './Components/ControlBoard/ControlBoard'
import SideBar from './Components/SideBar/SideBar'
import Shipment from './Components/Shipment/Shipment'
import Profile from './Components/Profile/Profile'
import Payments from './Components/Payments/Payments'
import ShipCompany from './Components/ShipCompany/ShipCompany'
import { Routes, Route } from "react-router-dom"
import ResetPassword from './Components/ResetPassword/ResetPassword'
import Privacy from './Components/Privacy/Privacy'
import Terms from './Components/Terms/Terms'

const App = () => {
  return (

    <Routes>
      
    <Route path="/home" element={<Home/>} />
    <Route path="/controlboard" element={<ControlBoard />} />
    <Route path="/shipment" element={<Shipment />} />
    <Route path="/payment" element={<Payments />} />
    <Route path="/shipcompany" element={<ShipCompany />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/" element={<Login />} />
    <Route path="/SignUp" element={<SignUp />} />
    <Route path="/ForgotPassword" element={<ForgotPassword />} />
    <Route path="/resetPassword" element={<ResetPassword/>} />
    <Route path="/privacy" element={<Privacy/>} />
    <Route path="/terms" element={<Terms/>} />
      </Routes>
    

    
  )
}

export default App
