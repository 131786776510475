import React from 'react'
import styles from "./Shipment.module.css"
import SideBar from '../SideBar/SideBar'
import Download from "../../Images/download.png"
import Serch from "../../Images/serch.png"
import ShipTable from './ShipTable'
const Shipment = () => {
  return (
    <div className={styles.shipmentMain}>
      <SideBar/>
      <div className={styles.shipmentContainer}>
        <div className={styles.controlMainButtonText}>
          <button className={styles.controlBtn}>غير موثق</button>
          <div className={styles.mainText}>ATHERR</div>
        </div>
        <div className={styles.shipmentButtondivMain}>
            <button className={styles.shipmentBtn}>انشاء +</button>
            <div className={styles.btnDivText}>
            جميع الشحنات
            </div>
        </div>
        <div className={styles.tableButtonMain}>
            <div className={styles.tableBtnMain}>
                <div className={styles.leftBtnMain}>
                <img src={Download} alt="" className={styles.downIcon} />
                <div className={styles.serchIconMain}>
                <img src={Serch} alt="" className={styles.serchIcon} />
                </div>
                </div>
                <div className={styles.rightBtnMain}>
                    <button className={styles.headbtn1}>To Date</button>
                    <button className={styles.headbtn1}>From Date</button>
                    
  <select id="" name="" className={styles.headbtn2}>
    <option value="اصدار بواسطة">اصدار بواسطة</option>
  </select>

                    <button className={styles.headbtn3}>رقم الفاتورة</button>
                    <button className={styles.headbtn4}>الحالة</button>
                    <button className={styles.headbtn5}>Pickup At</button>
                    <button className={styles.headbtn5}>Pickup At</button>
                    <button className={styles.headbtn6}>رقم الشحنة</button>
                    <button className={styles.headbtn7}>المبلغ</button>
                    <select id="" name="" className={styles.headbtn8}>
    <option value="نوع الدفع">نوع الدفع</option>
  </select>
                    <select id="" name="" className={styles.headbtn9}>
    <option value="شركة الشحن">شركة الشحن</option>
  </select>
                    <button className={styles.headbtn10}>اسم العميل</button>
                    <button className={styles.headbtn11}>سم المتجر  الاسم</button>
                </div>
            </div>
            <ShipTable/>
        </div>
    </div>
    </div>
  )
}

export default Shipment
