import React, { useEffect, useState } from 'react';
import axios from "axios";
import styles from "./Profile.module.css";
import SideBar from "../SideBar/SideBar";
import InputIcon from "../../Images/telephone.png";
import InputIcon2 from "../../Images/messages.png";
import InputIcon3 from "../../Images/company.png";
import Img from "../../Images/tableImg.png";
import "./Profile.css";
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
const Profile = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    mobile: "",
    email: "",
    name: "",
    licence:"",
    licenceNumber:"",
    address: "",
    managerNumber: "",
    managerName: "",
    licenceCopy: "",
    storeWeb: "",
    managerEmail: "",
    taxReg: "",
    promoCode: "",
    ibanNumber:"",
    bankName: "",
    accountName: "",
    count: "",
    emailNotifications: false, // State for email notifications checkbox
    smsNotifications: false, // State for SMS notifications checkbox
    codEnabled: false,
   
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  useEffect(() => {
    // Retrieve the user information from session storage
    const userInfo = sessionStorage.getItem('user');
    
    if (userInfo) {
      setFormData(JSON.parse(userInfo)); // Parse the string back to an object
    }
}, []);

// Log the actual token, not just the string 'authToken'
console.log('user', formData)
// console.log('user2', setFormData)


const [decodedToken, setDecodedToken] = useState(null);

useEffect(() => {
    const storedToken = sessionStorage.getItem('decodedToken');
    if (storedToken) {
        setDecodedToken(JSON.parse(storedToken));
    }
}, []);


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Make POST request to your backend API
  //     const response = await axios.post("https://q-ships.com/backend/api/profile", {
  //       mobile: formData.mobile,
  //   email: formData.email,
  //   name: formData.name,
  //   licence: formData.licence,
  //   licenceNumber: formData.licenceNumber,
  //   address: formData.address,
  //   managerNumber: formData.managerNumber,
  //   managerName: formData.managerName,
  //   licenceCopy: formData.licenceCopy,
  //   storeWeb: formData.storeWeb,
  //   managerEmail: formData.managerEmail,
  //   taxReg: formData.taxReg,
  //   promoCode: formData.promoCode,
  //   ibanNumber: formData.ibanNumber,
  //   bankName: formData.bankName,
  //   accountName: formData.accountName,
  //   count: formData.count,
  //   emailNotifications: formData.emailNotifications,
  //   smsNotifications: formData.smsNotifications,
  //   codEnabled: formData.codEnabled,

  //     });
  //     console.log("ProfileData saved successfully:", response.data);
  //     // const history = useHistory();
    
  //     // Redirect to another path after successful save
  //     // history.push('http://localhost:3000/profile'); 

  //     // Handle success, maybe show a success message to the user
  //     swal("Profile!", "Profile saved Successfully!", "success");
  //   } catch (error) {
  //     console.error("Error saving ProfileData:", error);
  //     // Handle error, maybe show an error message to the user
  //   }
  // };



  const handleSubmit = async (e) => {
    e.preventDefault();
const adminId = formData?._id
console.log(formData,"adminId")
    try {
      // Only send the fields that are not empty or modified
      // const updatedFields = Object.keys(formData)
      //   .filter((key) => formData[key] !== '' && formData[key] !== null)
      //   .reduce((obj, key) => {
      //     obj[key] = formData[key];
      //     return obj;
      //   }, {});

        const response = await axios.put(`https://q-ships.com/backend/api/update-admin/${adminId}`, formData);

    // Assuming you want to store the token or some other data from response in sessionStorage
    const token = response.data.token; // Example: change this to the data you want to store

    // Store token or admin data in sessionStorage

      swal("Profile!", "Profile updated Successfully!", "success");
      console.log('Updated Admin:', response.data);
      sessionStorage.setItem('user', JSON.stringify(response?.data?.data));
    } catch (error) {
      console.error('Error updating admin:', error);
      alert('Failed to update admin');
    }


  };

  return (
    <div className={styles.profileMain}>
      <SideBar />
      <div className={styles.profileContainer}>
        <div className={styles.controlMainButtonText}>
          <button className={styles.controlBtn}>غير موثق</button>
          <div className={styles.mainText}>ATHERR</div>
        </div>
        <div className={styles.profilemainHeading2}>ملفي</div>
        <form onSubmit={handleSubmit}>
          <div className={styles.profileTextMainSection}>
            <div className={styles.profileTextContSection}>
              <div className={styles.profileMainText}>المعلومات الاساسية</div>
              <div className={styles.profileInfoMain}>
                <div className={styles.profilePhoneMain}>
                  <div className={styles.phoneText}>الموبايل</div>
                  <div className={styles.InputMain1}>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="PhoneNumber"
                      className={styles.phoneInput1}
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                    <img src={InputIcon} alt="" className={styles.inputIcn} />
                  </div>
                </div>
                <div className={styles.profilePhoneMain}>
                  <div className={styles.phoneText}>الايميل</div>
                  <div className={styles.InputMain2}>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={styles.phoneInput2}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <img src={InputIcon2} alt="" className={styles.inputIcn} />
                  </div>
                </div>
                <div className={styles.profilePhoneMain}>
                  <div className={styles.phoneText}>اسم المتجر الاسم</div>
                  <div className={styles.InputMain3}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={styles.phoneInput3}
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <img src={InputIcon3} alt="" className={styles.inputIcn} />
                  </div>
                </div>
              </div>

              <div className={styles.infoHeading}>معلومات اضافية</div>
              <div className={styles.infomainDiv}>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>رقم الرخصة التجارية</div>
                  <input
                     type="text"
                     name="licenceNumber"
                     value={formData.licenceNumber}
                     onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>نوع الرخصة التجارية</div>
                  <input
                   type="text"
                   name="licence"
                   value={formData.licence}
                   onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>العنوان</div>
                  <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                    className={styles.infoText3}
                    placeholder="حي الشويكة شارع تيماء, القطيف, القطيف, SA"
                  />
                </div>
              </div>
              <div className={styles.infomainDiv2}>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>رقم هاتف مدير العمليات</div>
                  <input
                   type="text"
                   name="managerNumber"
                   value={formData.managerNumber}
                   onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>اسم مدير العمليات</div>
                  <input
                   type="text"
                   name="managerName"
                   value={formData.managerName}
                   onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>صورة عن الرخصة</div>
                  <input
                   type="text"
                   name="licenceCopy"
                   value={formData.licenceCopy}
                   onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
              </div>
              <div className={styles.infomainDiv3}>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>حالة الشركة</div>
                  <div className={styles.infoText4}>فعال</div>
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>
                    الموقع الالكتروني للمتجر
                  </div>
                  <input
                    type="text"
                    name="storeWeb"
                    value={formData.storeWeb}
                    onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>ايميل مدير العمليات</div>
                  <input
                    type="text"
                    name="managerEmail"
                    value={formData.managerEmail}
                    onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
              </div>
              <div className={styles.infomainDiv3}>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>السجل الضريبي</div>
                  <input
                     type="text"
                     name="taxReg"
                     value={formData.taxReg}
                     onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>الرمز الترويجي</div>
                  <input
                    type="text"
                   name="promoCode"
                   value={formData.promoCode}
                   onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>حالة التوثيق</div>
                  <div className={styles.infoText5}>غير موثق</div>
                </div>
              </div>
              <div className={styles.infoHeading}>معلومات البنك</div>
              <div className={styles.infomainDiv2}>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>رقم الآيبان</div>
                  <input
                     type="text"
                     name="ibanNumber"
                     value={formData.ibanNumber}
                     onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>اسم البنك</div>
                  <input
                      type="text"
                      name="bankName"
                      value={formData.bankName}
                      onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
                <div className={styles.infoDiv}>
                  <div className={styles.infoText}>أسم الحساب</div>
                  <input
                    type="text"
                    name="accountName"
                    value={formData.accountName}
                    onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
              </div>
              <div className={styles.infomainDiv2}>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>حساب اس تي سي</div>
                  <input
                    type="text"
                    name="count"
                    value={formData.count}
                    onChange={handleChange}
                    className={styles.infoText2}
                    placeholder="N/A"
                  />
                </div>
              </div>
              <div className={styles.infoButtonMain}>
                <button className={styles.infoBtn} type="submit">تعديل</button>
                <button className={styles.infoBtn2}>حذف الحساب</button>
              </div>

              <div className={styles.infoHeading2}>اعدادات التنبيهات</div>
              <div className={styles.infomainDiv2}>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>تنبيهات الايميل</div>
                  <div className={styles.infoSwitch}>
                    <label class="switch">
                      <input  type="checkbox"
                  name="emailNotifications"
                  checked={formData.emailNotifications}
                  onChange={handleChange} />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>تنبيهات المسجات القصيرة</div>
                  <div className={styles.infoSwitch}>
                    <label class="switch">
                      <input  type="checkbox"
                  name="smsNotifications"
                  checked={formData.smsNotifications}
                  onChange={handleChange} />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.infoHeading2}>الإعدادات</div>
              <div className={styles.infomainDiv2}>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>
                    اتاحة الدفع عند الاستلام
                  </div>
                  <div className={styles.infoSwitch}>
                    <label class="switch">
                      <input  
                      type="checkbox"
                name="codEnabled"
                checked={formData.codEnabled}
                onChange={handleChange} />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>
                    الحد الاقصى الافتراضي للمحفظة (دائن)
                  </div>
                  <div className={styles.infoText6}>0 ريال</div>
                </div>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>شركة الشحن المعتمدة</div>
                  <div className={styles.infoImage}>
                    <img src={Img} alt="" className={styles.imginfo} />
                  </div>
                </div>
              </div>

              <div className={styles.infomainDiv2}>
                <div className={styles.infoDiv2}>
                  <div className={styles.infoText}>
                    الحد الافتراضي للدفع عند الاستلام
                  </div>
                  <div className={styles.infoText6}>N/A</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
