import React from 'react'
import "./ShipTable.css"
import Table from 'react-bootstrap/Table';
import Image1 from "../../Images/tableImg.png"
import styles from "./ShipCompany.module.css"
import Image2 from "../../Images/tableImg.png"
import TImage1 from "../../Images/1tableimg.png"
import Image3 from "../../Images/3tableimg.png"
const ShipTable = () => {
  return (
       <div className="tableMain">
            <Table responsive>

      <thead>
        <tr className='tablemainhead'>
            <th>الإجراءات</th>
            <th>الحالة</th>
            <th>الباب للباب</th>
            <th>تكلفة زيادة وزن</th>
            <th>وزن اقصى</th>
            <th>كاش عند التوصيل</th>
            <th>دفع عند الاستلام</th>
            <th>السعر</th>
            <th>الإسم</th>
        </tr>
      
      </thead>
      <tbody>

        <tr>
            <td>
            <div className={styles.infoSwitch}>


<label class="switch">
  <input type="checkbox"/>
  <span class="slider round"></span>
</label>

                    </div>
             </td>
            <td style={{textAlign:"right"}}>
              <button className="tbbtn3">متوفر</button>
              </td>
            <td className='Text6'>لا</td>
            <td className='Text6'>
            2 ريال
                 </td>
                 <td className='Text6'>
                 15
                 </td>
                 <td className='Text6'>10 ريال</td>
                 <td className='Text6'>لا</td>
                 <td className='Text6'>15 ريال</td>
                 <td style={{textAlign:"right"}} >
                  <img src={TImage1} alt="" className={styles.imgtable}/> </td>
        
        </tr>
        <tr>
            <td>
            <div className={styles.infoSwitch}>


<label class="switch">
  <input type="checkbox"/>
  <span class="slider round"></span>
</label>

                    </div>
             </td>
            <td style={{textAlign:"right"}}>
              <button className="tbbtn3">متوفر</button>
              </td>
            <td className='Text6'>لا</td>
            <td className='Text6'>
            2 ريال
                 </td>
                 <td className='Text6'>
                 15
                 </td>
                 <td className='Text6'>10 ريال</td>
                 <td className='Text6'>لا</td>
                 <td className='Text6'>15 ريال</td>
                 <td style={{textAlign:"right"}} >
                  <img src={Image2} alt="" className={styles.imgtable}/> </td>
        
        </tr>
        <tr>
            <td>
            <div className={styles.infoSwitch}>


<label class="switch">
  <input type="checkbox"/>
  <span class="slider round"></span>
</label>

                    </div>
             </td>
            <td style={{textAlign:"right"}}>
              <button className="tbbtn3">متوفر</button>
              </td>
            <td className='Text6'>لا</td>
            <td className='Text6'>
            2 ريال
                 </td>
                 <td className='Text6'>
                 15
                 </td>
                 <td className='Text6'>10 ريال</td>
                 <td className='Text6'>لا</td>
                 <td className='Text6'>15 ريال</td>
                 <td style={{textAlign:"right"}} >
                  <img src={Image3} alt="" className={styles.imgtable}/> </td>
        
        </tr>
      
     
       
      </tbody>
    </Table>
    </div>
  )
}

export default ShipTable
