import React from 'react'
import styles from "./Privacy.module.css"
const Privacy = () => {
  return (
    <div className={styles.privacyMain}>
      <div className={styles.privacyContainer}>
        <div className={styles.privacyHeading}>
        سياسة الخصوصية 
        </div>
        <div className={styles.privacyText}>
        عزيزنا مستخدم  موقع (اسم الموقع) أن أمن بياناتك هي اكبر أولوياتنا ونسعى لحماية هذه الخصوصية وتنطبق هذه الوثيقة  على مستخدمي الموقع مزودي الخدمة والعملاء الذين يطلبون الخدمات  كذلك  الخدمات التي  نقدمها والأشخاص الذين يعملون في التطبيي او تحت إدارتها او  الذين يقدمون الخدمات او اي  جهات أخرى تنضم إلينا بأي وجه من الوجوه  فمن خلال وثيقة سياسة الخصوصية سوف نوضح   كيفية حماية البيانات واستخدامها من طرفنا أو من أي طرف ثالث حسب الآتي
        </div>
        <div className={styles.privacyHeading}>
        - من يمكنه الوصول إلى بياناتك الشخصية؛
        </div>
        <div className={styles.privacyText}>
        إدارة  الموقع  لها  الإمكانية الكاملة للوصول إلى البيانات الشخصية  والمعلومات التي تطلبه من المستخدم عند التسجيل من أجل معالجتها والإيفاء لمتطلبات الخدمات المقدمة    
     <br />
     و  يتم الإفصاح عن هذه المعلومات لجهات إنفاذ القانون مثل السلطات القضائية والادارية  وذلك لحماية اي حقوق قانونية او التحقيق  بشأنها وفقا للقانون الساري في المملكة العربية السعودية و لا نقوم بتجميع بيانات   لطرف ثالث او شركاء أو  مواقع ولا نشارك المعلومات للعامة  

        </div>
        <div className={styles.privacyHeading}>
        - كيف نحمي أمن بياناتك الشخصية؛
        </div>
        <div className={styles.privacyText}>
        ان إدارة الموقع تبذل قصارى جهدها لحماية بيانات المستخدمين و تتبع إجراءات أمنية مادية وإلكترونية وتقنية وتدابير أمنية وتنظيمية مناسبة  لحماية البيانات الشخصية وفقا لقانون حماية البيانات من الوصول غير المصرح به إلى البيانات الشخصية أو استخدامها أو تغييرها أو الإفصاح عنها أو إتلافها ولكن مع ذلك نرجو من مستخدمينا ملاحظة أنه لا يمكن ضمان نقل أية بيانات عبر الإنترنت أو الموقع الإلكتروني من دون أي تدخل بالإضافة إلى ذلك يتم  اتخاذ الإجراءات المناسبة لحماية البيانات من الوصول غير المصرح به بوضع إجراءات معايير الصناعة من اجل منع أي اختراق أو إساءة استخدام وفي حال وجود أي استخدام غير مصرح به فإن إدارة الموقع سوف تتخذ إجراءات صارمة حيال ذلك 
        </div>
        <div className={styles.privacyHeading}>
        - هل يمكن للأشخاص القاصرين تزويدنا ببياناتهم الشخصية؛
        </div>
        <div className={styles.privacyText}>
        هذا الموقع غير مسموح الاستخدام للقصر او من في حكمهم وفقا للأنظمة الساري في المملكة العربية السعودية فبالتالي إدارة الموقع تنصح أولياء القصر او الاوصياء القانونيين ببذل العناية للازمة  وعدم السماح للقصر ومن في حكمهم بإستخدام الموقع
        </div>
        <div className={styles.privacyHeading}>
        - ما هي حقوقك؛
        </div>
        <div className={styles.privacyText}>
        بموجب القانون الساري يحق للمستخدم ممارسة حقوق معينة بشأن الحق في الوصول إلى الخدمات المقدمة، وفي هذه الحالة قد قد يوفر الموقع للمستخدم نسخة من هذه البيانات، إن لم يكن ذلك متاحا له 
        </div>
        <div className={styles.privacyHeading}>
        -  سياسة الخصوصية  مع معالجة بيانات من طرف ثالث(الكوكيز) ؛
        </div>
        <div className={styles.privacyText}>
        الموقع لا يستخدم ملفات تعريف الارتباط    (كوكيز). وهي ملفات نصية صغيرة يتم من خلالها حفظ المعلومات الأساسية التي يستخدمها الموقع
        </div>
        <div className={styles.privacyHeading}>
        - كيفية  تحديث سياسة الخصوصية
        </div>
        <div className={styles.privacyText}>
        هذه الوثيقة قابلة للتعديل   في أي  وقت وذلك استجابة للمتطلبات القانونية أو التنظيمية أو التشغيلية دون إعلام المستخدمين فنرجوا قراءة سياسة الخصوصية من وقت لآخر
        </div>
      </div>
    </div>
  )
}

export default Privacy
