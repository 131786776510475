import React from 'react'
import styles from './Terms.module.css'
const Terms = () => {
  return (
    <div className={styles.termsMain}>
      <div className={styles.termsContainer}>
        <div className={styles.termsMainHeading}>
        الشروط والاحكام 
        </div>
        <div className={styles.termsHeading}>
        مقدمة  
        </div>
        <div className={styles.termsText}>
        عزيزنا المستخدم أن (اسم الموقع) يقدم منتجات وخدمات رقمية تشمل شحن الألعاب وبيع بطاقات رقمية ومنتجات أخرى ذات طبيعة رقمية وان مجرد تصفحك للموقع يعتبر موافقة منك على الشروط والأحكام الواردة في هذه الوثيقة وأنك تقر وتقبل بتطبيق سياسة الخصوصية الخاصة بنا وكل القوانين التي تنظم التجارة الالكترونية ويجب على المستخدمين أن يكونوا على علم بأن هذه المنتجات والخدمات تقدم  بعد اكتمال عملية الدفع ولا يمكن استرجاع المبلغ أو إلغاء الطلب .
        </div>
        <div className={styles.termsHeading}>
        تعريف         </div>
        <div className={styles.termsText}>
        إن هذه الوثيقة  تحتوي على جميع الشروط العامة  كما تشمل الأحكام والشروط والسياسات وإخلاء المسؤولية، والصلاحيات والإشعارات الأخرى ذات الصلة التي يتم الاتفاق فيما بيننا  ونقدم هذه الخدمات وفق شروط الاستخدام هذه  والضوابط  التى يحددها الموقع ويشار إليه في هذه الوثيقة باسم ( موقع ……. ) ( نحن ) أو ( لنا ) أو ( إدارة الموقع) ويشار للمستخدم بإسم (صيغة المخاطب) أو (انت) أو (المستخدم)
        </div>
        <div className={styles.termsHeading}>
        شروط الاستخدام 
          </div>
        <div className={styles.termsTextlist}>
        إن استخدام خدمات الموقع متاحة فقط للأشخاص القادرين على تمثيل أنفسهم قانونياً وفق القوانين السارية في النطاق الجغرافي الذي يتم فيه تصفح الموقع لا يمكن للأشخاص القصر ومن في حكمهم استخدام خدمات الموقع.
        </div>
        <div className={styles.termsTextlist}>
        إن استخدام خدمات الموقع متاحة فقط للأشخاص القادرين على تمثيل أنفسهم قانونياً وفق القوانين السارية في النطاق الجغرافي الذي يتم فيه تصفح الموقع لا يمكن للأشخاص القصر ومن في حكمهم استخدام خدمات الموقع.
        </div>
        <div className={styles.termsTextlist}>
        باستخدامك لخدمات الموقع ستكون ملزماً باتباع بنود الشروط والأحكام . 
        </div>
        <div className={styles.termsText}>
        عزيزنا المستخدم  أن الموقع لا يتعامل مع القصر ومن في حكمهم إلا تحت إشراف الأوصياء القانونيين لذلك فأنك تقر وتتعهد بأن عمرك لا يقل عن 18 عامًا  ونرجو من الأوصياء القانونيين 
        <br />
        ويجوز لنا  وفقًا لتقديرنا الخاص رفض تقديم الخدمات إلى أي شخص أو كيان وتغيير معايير الأهلية الخاصة به في أي وقت. 

        </div>
        <div className={styles.termsTextlist2}>
        أنت وحدك المسؤول عن ضمان امتثال هذه الشروط والأحكام لجميع الانظمة والقواعد واللوائح السارية وعليك إلغاء  الوصول إلى الخدمات عندما تكون هذه الشروط والأحكام أو استخدام الخدمات محظورًا أو إلى الحد المقدم أو يتعارض مع بيع أو توفير الخدمات مع أي نظام أو قاعدة او قانون ساري المفعول  علاوة على ذلك ، ويتم تقديم الخدمات لاستخدامك فقط وليس لمنفعة أي طرف ثالث    
        </div>
        <div className={styles.termsTextlist2}>
        أنك تتمتع  بالاهلية القانونية ومسؤول عن التعاقد وأنك بذلك لن تكون منتهكاً لأي قانون أو عقد، وأنه لا يوجد عليك أي سوابق قضائية  أو مطالبات لدى الجهات الأمنية. 
        </div>
        <div className={styles.termsTextlist2}>
        انك متعاقد اصيل وليس وسيط بين التطبيق و عميل آخر
        </div>
        <div className={styles.termsTextlist2}>
        تتعهد بأنك تقدم معلومات صحيحة ودقيقة إلى الموقع في حال قامت إدارة الموقع بطلب تلك المعلومات 
        </div>
        <div className={styles.termsTextlist2}>
        تقر بأنك سوف تحافظ على حسابك من خلال رقم الهاتف أو البريد أي وسيلة تعريف نقدمها لك وتتيح الدخول إلى حسابك، بشكلٍ آمن وسري.
        </div>
        <div className={styles.termsTextlist2}>
        يجب استخدام الموقع  للأغراض التى يقرها القانون وبطريقة  لا تعتدى على حقوق أي فرد في استخدام المحتوى ولا يجوز استخدامه في أغراض إجرامية كالاحتيال والتضليل أو الإساءة للمعتقدات الدينية  او العرقية او أي سلوك تجرمه القوانين السارية. 
        </div>
        <div className={styles.termsTextlist2}>
        لا يجوز اطلاقا استخدام بطاقة الدفع التي لا تخص المستخدم  وكل من يخالف هذا البند ويسبب ضرر للموقع سوف يكون للموقع الحق  في مقاضاته وطلب التعويض العادل مقابل كل خسارة يتسبب فيها
        </div>
        <div className={styles.termsTextlist2}>
        لا يجوز استخدام أو تصفح الموقع بأي طريقة من شأنها خرق  القوانين في الإمارات العربية المتحدة أو في أي نطاق جغرافي تتصفح فيه الموقع
        </div>
        <div className={styles.termsTextlist2}>
        لا يجوز إستخدام هذا الموقع بأية طريقة غير قانونية أو إحتيالية،  أو يكون لها غرض أو تأثير غير قانوني أو احتيالي و   لا يجوز نشر أو إرسال أو بث أو أي وسيلة أخرى كانت و تتيح أي معلومات على هذا  الموقع وعلى سبيل المثال وليس الحصر أن تكون مضرة أو تهديدية للانظمة المشغلة أو منافية للآداب العامة أو مزعجة  أو تحمل تمييزاً عنصرياً أو عرقياً أو غير قانونية .
        </div>
        <div className={styles.termsTextlist2}>
        لا يجوز تعمد استخدام أي برنامج إلكتروني أو وسيلة أو جهاز للتدخل أو محاولة التدخل إلكترونياً أو يدوياً في عملية التشغيل أو الأداء الوظيفي للموقع بقصد الإضرار بالموقع او الدخول غير المصرح به أو إساءة استخدام الموقع.
        </div>
        <div className={styles.termsTextlist2}>
        يقر المستخدم إن كل معلوماته في هذه الموقع صحيحة ومحدثة ويتحمل المسؤولية الكاملة في حال تقديم معلومات مضللة  أو ينتحل شخصية شخص آخر 
        </div>
        <div className={styles.termsTextlist2}>
        يسمح الدخول إلى الخدمات على أساس مؤقت، ويحتفظ الموقع بحق سحب أو تعديل الخدمة التي يقوم بتقديمها دون إشعار مسبق. ولا يتحمل أي مسؤولية ناتجة من عدم توفر الخدمات وقد تتوقف هذه الخدمات لأسباب قاهرة فالمستخدم يعفي الموقع  من مسؤولية هذا التوقف. 
        </div>
        <div className={styles.termsHeading}>
        إخلاء المسؤولية 
        </div>
        <div className={styles.termsTextlist}>
        موقع (.......) يقوم بتقديم الخدمة مع بذل العناية الواجبة  لكن مع ذلك لا تخلو أي خدمة من أخطاء غير مقصودة  لذلك فإن  الموقع خالي المسؤوليه عن كافة الضمانات والكفالات أو الأحكام فبدون تحديد ما سبق و إلى الحد الذي يسمح به القانون، نحن نستثني ونخلي مسؤوليتنا عن أي ضمانات ضمنية، بما في ذلك تلك المُرتبطة بالتسويق، أو الملاءمة لغرض معين، أو الجهود الحثيثة وعدم التعدي. بالإضافة إلى ذلك، لن يكون الموقع مسؤول بأي حالٍ من الأحوال تجاهك أو تجاه أي شخص عن أي قرارٍ مُتخذ أو إجراءٍ قام به أىٌ من الأطراف كذلك يكون الموقع خالي المسؤولية من  أي محتوى له حقوق ملكية لأشخاص آخرين 
        </div>
      </div>
    </div>
  )
}

export default Terms
