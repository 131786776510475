import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import SignBg from "../../Images/signbg.png";
import SignLogo from "../../Images/signlogo.png";
import axios from 'axios';
import swal from 'sweetalert';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://q-ships.com/backend/api/forgotAdmin', formData); // Await the response
      console.log(response.data); // Log response from server
      // Handle successful login, e.g., store token in local storage and redirect to dashboard
      swal("ForgotPassword!", "ForgotPassword Successfully!", "success");
    } catch (error) {
      if (error.response) {
        console.error("ForgotPassword failed:", error.response.data.message);
      } else {
        console.error("ForgotPassword failed:", error.message);
      }
      // Handle login failure, e.g., show error message to the user
    }

    setFormData({
      email: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={styles.signupMain}>
      <div className={styles.signupContainer}>
        <div className={styles.signupLeftsideMain}>
          <img src={SignBg} alt="" className={styles.signBgImg} />
          <img src={SignLogo} alt="" className={styles.signlogoImg} />
        </div>
        <div className={styles.signupRightsideMain}>
          <div className={styles.signHeading}>
            Forget Password
          </div>
          <div className={styles.signText}>
            Enter your email to reset password
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.signInputMain}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder='الايميل'
                className={styles.signinput} />
            </div>
            <div className={styles.signButton}>
              <button className={styles.signBtn} type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
