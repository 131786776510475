import React, { useEffect, useState } from 'react';
import styles from "./Login.module.css";
import SignBg from "../../Images/signbg.png";
import SignLogo from "../../Images/signlogo.png";
import Tick from "../../Images/logintick.png";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { jwtDecode } from "jwt-decode"; // Import jwtDecode as a named import
const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    
    try {
      const response = await axios.post('https://q-ships.com/backend/api/loginAdmin', formData);

      // Assuming the token is in response.data.token
      const token = response.data.token;
  
          // Store the token in sessionStorage


      // Decode the token
      const decodedToken = jwtDecode(token);
       // Store the decoded token in sessionStorage
       sessionStorage.setItem('user', JSON.stringify(decodedToken));
      // Log the token and the decoded token
      console.log("Token:", token);
      console.log("Decoded Token:", decodedToken);
      swal("Login!", "Login Successfully!", "success");
      navigate("/home");
    } catch (error) {
      console.error("Login failed:", error.response.data.message);

      // Handle login failure, e.g., show error message to the user
    }

    setFormData({
      email: "",
      password: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };




  return (
    <div className={styles.signupMain}>
      <div className={styles.signupContainer}>
        <div className={styles.signupLeftsideMain}>
          <img src={SignBg} alt="" className={styles.signBgImg} />
          <img src={SignLogo} alt="" className={styles.signlogoImg} />
        </div>
        <div className={styles.signupRightsideMain}>
          <div className={styles.signHeading}>تسجيل دخول</div>
          <div className={styles.signText}>
            استخدم نفس معلومات الدخول اثناء التسجيل
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.signInputMain}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="الايميل"
                className={styles.signinput}
              />
            </div>
            <div className={styles.signInputMain}>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                placeholder="كلمة المرور *"
                className={styles.signinput}
              />
            </div>
            <div className={styles.tickMain}>
              <div className={styles.tickImage}>
                <img src={Tick} alt="" className={styles.tickImg} />
              </div>
              <div className={styles.tickText}>أبقني متصل</div>
            </div>
            <div className={styles.signButton}>
              <button className={styles.signBtn} type="submit">تسجيل دخول</button>
            </div>
          </form>
          <div className={styles.signLastText}>
            بتسجيل الدخول انت توافق على سياسة الخصوصية وشروط
          </div>
          <div className={styles.signLastText}>الاستخدام.</div>
          <div className={styles.signLastText}>
            جديد في منصة آي كومكس؟ 
            <Link to="/SignUp" style={{textDecoration:"none"}}>
              <span> عمل حساب جديد</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
