import React from 'react'
import styles from "./Home.module.css"
import Btn1Icon from "../../Images/btnicon1.png"
import Btn2Icon from "../../Images/btnicon2.png"
import RightImage from "../../Images/secImg.png"
import Service1 from "../../Images/service1.png"
import Service2 from "../../Images/service2.png"
import Service3 from "../../Images/service3.png"
import Service4 from "../../Images/service4.png"
import Partner1 from "../../Images/comImg1.jpg"
import Partner2 from "../../Images/comImg2.jpg"
import Partner3 from "../../Images/partner3.png"
import Partner4 from "../../Images/partner4.png"
import Header from '../Header/Header'
import Accordian from './Accordian'
import Footer from '../Footer/Footer'
const Home = () => {
  return (
    <div>
        <Header/>
    <div className={styles.homeMain}>
        <div className={styles.homeContainerMain}>
            
      <div className={styles.homeContainer}>
        <div className={styles.homeHeading}>
        حلول الشحن المبتكرة  على  بعد  خطوة
        </div>
        <div className={styles.homeText}>
        نلبي احتياجات شحنك بكفاءة ودقة      
      </div>
        <div className={styles.homeButton}>
            <button className={styles.homeBtn}>
            سجل الآن
            </button>
      </div>
        <div className={styles.homeButton2Main}>
            <button className={styles.homeBtn1}>
                <div className={styles.btnTextInner}>
                شركات شحن  متنوعة         
                </div>
            <img src={Btn2Icon} alt="" className={styles.btnicon1} /> 
              </button>
            <button className={styles.homeBtn1}>
            <div className={styles.btnTextInner2}>
            اشحن  بسهولة  وسرعة
            </div>
            
            <img src={Btn1Icon} alt="" className={styles.btnicon2} /> 
            
            </button>

      </div>
      </div>
      </div>

      <div className={styles.SecondContainerMain}>
        <div className={styles.secondLeftSide}>
            <div className={styles.secondLeftHeading}>
            من  هم  كيو  شيبس؟
            </div>
            <div className={styles.secondLeftText}>
            نحن في شركة كيو نفتخر بتقديم خدمات شحن مبتكرة ومتقدمة تجمع بين التكنولوجيا والاحترافية لتلبية احتياجات عملائنا الكرام. نسعى جاهدين لتحسين تجربة الشحن لعملائنا من خلال استخدام أحدث التقنيات والحلول الذكية. نحن ملتزمون بتوفير حلول شحن مبتكرة تساهم في نجاح أعمالكم وتسهم في تحقيق أهدافكم التجارية
            </div>
            <div className={styles.secondLeftText}>
            مهمتنا هي تحقيق رضا العملاء من خلال تقديم خدمات شحن موثوقة، وآمنة، وفعالة، وبتكلفة تنافسية. نحن نسعى دائمًا للابتكار والتطوير، ونتطلع إلى خدمتكم وتلبية احتياجاتكم في مجال الشحن بأفضل الطرق الممكنة
            
            </div>
            <div className={styles.secondLeftButton}>
            <button className={styles.secondleftsideBtn}>
            سجل  معنا  وانطلق  نحو  النجاح
            </button>
            </div>

        </div>
        <div className={styles.secondRightSide}>
        <img src={RightImage} alt="" className={styles.secondrightImg} />
      </div>
      </div>

     <div className={styles.midSectionMain}>
        <div className={styles.midSectionHeading}>
        ماذا  يمكننا  ان  نقدم  لكم؟
        </div>
        <div className={styles.midSectionText}>
        نحن  في  كيو  التقنية نمتلك  فريقا مكون من مجموعة من الخبراء والمحترفين في مجال الشحن وتكنولوجيا المعلومات، الذين يعملون بتفانٍ لضمان تقديم خدمات عالية الجودة وفعالة لعملائنا. نحن ملتزمون بتوفير حلول شحن مبتكرة تساهم في نجاح أعمالكم وتسهم في تحقيق أهدافكم التجارية.
        </div>
      </div>
   

   <div className={styles.serviceMain}>
    <div className={styles.serviceHeading}>
    حول شحناتك مع كيو شيبس  
    </div>
    <div className={styles.serviceIconsTextMain}>
      <div className={styles.iconMain}>
        <div className={styles.IconDiv}>
          <img src={Service1} alt="" className={styles.serIcon1} />
         
        </div>
        <div className={styles.iconText}>
        مرونة 
          </div>
      </div>
      <div className={styles.iconMain}>
        <div className={styles.IconDiv}>
          <img src={Service2} alt="" className={styles.serIcon1} />
     
        </div>
        <div className={styles.iconText}>
          خدمات سريعة
          </div>
      </div>
      <div className={styles.iconMain}>
        <div className={styles.IconDiv}>
          <img src={Service3} alt="" className={styles.serIcon1} />
       
        </div>
        <div className={styles.iconText}>
          الشفافية
          </div>
      </div>
      <div className={styles.iconMain}>
        <div className={styles.IconDiv}>
          <img src={Service4} alt="" className={styles.serIcon1} />
        
        </div>
        <div className={styles.iconText}>
          شغف
          </div>
      </div>
    </div>
   </div>


   <div className={styles.deliveryMain}>
    <div className={styles.deliveryContainer}>
      <div className={styles.delivery1Heading}>
      اسعار شحن  منافسة
      </div>
      <div className={styles.delivery1Text}>
      اختر  شركة  الشحن،  املأ  معلوماتك،  ادفع..  بكل  سهولة  ويسر     
       </div>
       <div className={styles.delivery2HeadingMain}>
        <div className={styles.delivery2Heading}>
        25 SR
        </div>
        <div className={styles.delivery3HeadingMain}>
          <div className={styles.delivery3Heading}>
          ريال
          </div>
          <div className={styles.delivery3Text}>
          اشحن  ابتداء  من
          </div>
        </div>
       </div>
    </div>
   </div>



   <div className={styles.partnersMain}>
    <div className={styles.partnersHeadingMain}>
    شركاء النجاح
    </div>
    <div className={styles.partnersMainImages}>
      <img src={Partner1} alt="" className={styles.partnerImg} />
      <img src={Partner2} alt="" className={styles.partnerImg} />
      <img src={Partner3} alt="" className={styles.partnerImg} />
      <img src={Partner4} alt="" className={styles.partnerImg} />
    </div>
    <div className={styles.accordianHeading}>
    الأسئلة المتكررة
    </div>
    <div className={styles.accordianMain}>
      <Accordian/>
    </div>
   </div>
    </div> 
<Footer/>

    </div>

  )
}

export default Home
