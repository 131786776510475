import React from 'react'
import "./ShipTable.css"
import Table from 'react-bootstrap/Table';
import Image1 from "../../Images/tableImg.png"
const ShipTable = () => {
  return (
    <div className="tableMain">
            <Table responsive>

      <thead>
        <tr className='tablemainhead'>
            <th>الإجراءات</th>
            <th>اصدار بواسطة</th>
            <th>رقم الفاتورة</th>
            <th>الحالة</th>
            <th>تاريخ الاستلام</th>
            <th>رقم الشحنة</th>
            <th>المبلغ</th>
            <th>قيمة الشحن</th>
            <th>النوع</th>
            <th>شركة الشحن</th>
            <th>اسم العميل</th>
            <th>اسم المتجر الاسم</th>
        </tr>
      
      </thead>
      <tbody>

        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
        <tr>
            <td>
                <div className="ButoontextMain">
              <button className="btntext">عرض</button>
              </div>
             </td>
            <td className="Text2">يدوي</td>
            <td></td>
            <td className="Text2">Shipment Placed</td>
            <td className="Text2">Mar 17, 2024</td>
            <td className='Text2'>50076825761</td>
            <td className='Text2'>26.45 ريال</td>
            <td className='Text2'>37 ريال</td>
            <td>
                <div className="ButoontextMain2">
                    <button className="btntext2">دفع اونلاين</button>
                </div>
                 </td>
                 <td>
                    <img src={Image1} alt="" className='tableImg' />
                 </td>
                 <td className='Text3'>حصة القحطاني</td>
                 <td className='Text4'>ATHERR</td>
        
        </tr>
      
     
       
      </tbody>
    </Table>
    </div>
  )
}

export default ShipTable
